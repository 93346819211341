exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-js": () => import("./../../../src/pages/acerca.js" /* webpackChunkName: "component---src-pages-acerca-js" */),
  "component---src-pages-anomalias-js": () => import("./../../../src/pages/anomalias.js" /* webpackChunkName: "component---src-pages-anomalias-js" */),
  "component---src-pages-envipe-js": () => import("./../../../src/pages/envipe.js" /* webpackChunkName: "component---src-pages-envipe-js" */),
  "component---src-pages-estados-diferencia-js": () => import("./../../../src/pages/estados-diferencia.js" /* webpackChunkName: "component---src-pages-estados-diferencia-js" */),
  "component---src-pages-estados-js": () => import("./../../../src/pages/estados.js" /* webpackChunkName: "component---src-pages-estados-js" */),
  "component---src-pages-estados-ranking-js": () => import("./../../../src/pages/estados-ranking.js" /* webpackChunkName: "component---src-pages-estados-ranking-js" */),
  "component---src-pages-feminicidio-js": () => import("./../../../src/pages/feminicidio.js" /* webpackChunkName: "component---src-pages-feminicidio-js" */),
  "component---src-pages-homicidios-mujeres-js": () => import("./../../../src/pages/homicidios-mujeres.js" /* webpackChunkName: "component---src-pages-homicidios-mujeres-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infograficas-js": () => import("./../../../src/pages/infograficas.js" /* webpackChunkName: "component---src-pages-infograficas-js" */),
  "component---src-pages-mapa-clusters-js": () => import("./../../../src/pages/mapa-clusters.js" /* webpackChunkName: "component---src-pages-mapa-clusters-js" */),
  "component---src-pages-mapa-de-delincuencia-js": () => import("./../../../src/pages/mapa-de-delincuencia.js" /* webpackChunkName: "component---src-pages-mapa-de-delincuencia-js" */),
  "component---src-pages-municipios-js": () => import("./../../../src/pages/municipios.js" /* webpackChunkName: "component---src-pages-municipios-js" */),
  "component---src-pages-municipios-mas-violentos-js": () => import("./../../../src/pages/municipios-mas-violentos.js" /* webpackChunkName: "component---src-pages-municipios-mas-violentos-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-tasa-crimen-js": () => import("./../../../src/pages/tasa-crimen.js" /* webpackChunkName: "component---src-pages-tasa-crimen-js" */),
  "component---src-pages-tendencias-estado-js": () => import("./../../../src/pages/tendencias-estado.js" /* webpackChunkName: "component---src-pages-tendencias-estado-js" */),
  "component---src-pages-tendencias-js": () => import("./../../../src/pages/tendencias.js" /* webpackChunkName: "component---src-pages-tendencias-js" */),
  "component---src-pages-turismo-js": () => import("./../../../src/pages/turismo.js" /* webpackChunkName: "component---src-pages-turismo-js" */)
}

